<template>
  <div>
    <v-progress-linear
      :active="getLoaderStatus"
      indeterminate
      fixed
      top
      color="primary"
      height="5px"
      style="z-index: 500"
    ></v-progress-linear>
    <snackbar ref="snackbar" :message="snackbarMessage"></snackbar>
    <v-navigation-drawer
      :value="getToggleSlider"
      :key="`offer-1`"
      fixed
      temporary
      :stateless="keepOpen"
      overlay-opacity="0.8"
      right
      width="80%"
      class="fill-height"
    >
      <v-toolbar outlined flat dense light class="navbar-fixed-header">
        <v-btn icon class="mr-1" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-divider vertical></v-divider>
        <h5 class="ml-5 text-uppercase" v-if="formType === 'add'">Add Plan</h5>
        <h5 class="ml-5 text-uppercase" v-if="formType === 'edit'">
          Update Plan
        </h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-form ref="form" v-model="validForm" lazy-validation>
        <v-col class="max-width" v-if="form">
          <h6 class="ml-2">Plan Information</h6>
          <v-col>
            <v-text-field
              label="Name"
              v-model="form.name"
              :rules="[getRules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              label="Billing Period"
              :items="[{ name: 'Yearly', value: 'yearly' }]"
              item-text="name"
              item-value="value"
              v-model="form.billing_period"
              :rules="[getRules.required]"
            ></v-select>
          </v-col>
          <!--<v-col>
              <v-text-field
                  label="Trial Duration Days"
                  type="number"
                  min="0"
                  v-model="form.trial_duration_days"
                  :rules="[getRules.required]"
              ></v-text-field>
            </v-col>-->
          <v-col>
            <v-text-field
              label="Grace Days"
              type="number"
              min="0"
              v-model="form.grace_days"
              :rules="[getRules.required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Unit Price"
              type="number"
              min="0"
              v-model="form.unit_price"
              :rules="[getRules.required]"
            ></v-text-field>
          </v-col>
          <v-col v-if="formType === 'add'">
            <v-select
              label="Type"
              v-model="form.type"
              :rules="[getRules.required]"
              item-text="name"
              item-value="value"
              :items="[
                { name: 'Registration Fee', value: 'registration_fee' },
                { name: 'Listing Fee', value: 'listing_fee' },
              ]"
            ></v-select>
          </v-col>
          <v-btn color="primary" @click="validate" class="mt-5">
            <span v-if="formType === 'add'">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add Plan
            </span>
            <span v-else>
              <v-icon class="mr-2">mdi-pencil</v-icon>
              Update Plan
            </span>
          </v-btn>
        </v-col>
      </v-form>
    </v-navigation-drawer>
    <mice-navigation @vendor-list-mini="minimizeList"></mice-navigation>
    <top-nav-bar :mini="mini"></top-nav-bar>
    <v-main
      :class="{ 'detail-content-margin': listingDrawer }"
      :style="[mini ? { 'padding-left': '56px' } : { 'padding-left': '232px' }]"
      class="details-content"
      v-if="getAuthUser.user_type === 'admin'"
    >
      <v-col>
        <v-card class="ma-5" elevation="15">
          <div>
            <div class="text-h6 pt-4 ma-2">Plan Management</div>
            <v-divider class="mt-8 mb-4" color="default"></v-divider>
            <v-row class="ma-2">
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  clearable
                  single-line
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn color="primary" outlined @click="openPlanSlider('add')">
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  New Plan
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="my-4" color="default"></v-divider>
            <v-data-table
              :loading="false"
              :search="search"
              :headers="headers"
              :items="getPlans"
              class="elevation-1"
              group-by="type"
              :show-group-by="true"
            >
              <template v-slot:group.header="{ items, isOpen, toggle }">
                <th colspan="7">
                  <v-icon @click="toggle">{{
                    isOpen ? "mdi-minus" : "mdi-plus"
                  }}</v-icon>
                  {{ capitalizeTheFirstLetterOfEachWord(items[0].type) }}
                </th>
              </template>

              <!--<template v-slot:item.trial_duration_days="{ item }">
                {{daysText(item.trial_duration_days)}}
              </template>-->

              <template v-slot:item.grace_days="{ item }">
                {{ daysText(item.grace_days) }}
              </template>
              <template v-slot:item.unit_price="{ item }">
                <strong
                  ><span class="text-uppercase">€</span>
                  {{ item.unit_price | applyDecimal }}</strong
                >
              </template>
              <template v-slot:item.currency="{ item }">
                {{ item.currency }}
              </template>

              <template v-slot:item.is_default="{ item }">
                <v-chip
                  v-if="item.is_default === 1"
                  x-small
                  label
                  :color="item.is_default | defaultPlanColor"
                  class="ml-2 my-1 border-radius"
                  text-color="white"
                >
                  {{ item.is_default === 1 ? "default" : "not default" }}
                </v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      small
                      class="mr-2"
                      @click.stop="openPlanSlider('edit', item.id)"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Edit Plan</span>
                </v-tooltip>

                <v-tooltip top v-if="item.is_default === 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" small @click="deletePlanItem(item)"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete Plan</span>
                </v-tooltip>

                <v-tooltip top v-if="item.is_default === 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      class="ml-2"
                      small
                      @click="setAsDefault(item)"
                      >mdi-check-circle-outline</v-icon
                    >
                  </template>
                  <span>Set as default</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Snackbar from "@/components/Snackbar";
const TopNavBar = () =>
  import(/* webpackChunkName: "top-nav-bar" */ "@/components/TopNavBar");
export default {
  name: "Plans",
  components: { TopNavBar, Snackbar },
  data: () => ({
    mini: false,
    navigation: 0,
    listingDrawer: true,
    subscriptionDialog: false,
    search: "",
    headers: [
      { text: "Name", value: "name", groupable: false, width: "13%" },
      {
        text: "Billing Period",
        value: "billing_period",
        groupable: false,
        width: "13%",
      },
      //{text: 'Trial Period', value: 'trial_duration_days', groupable: false},
      {
        text: "Unit Price",
        value: "unit_price",
        groupable: false,
        width: "13%",
      },
      { text: "Currency", value: "currency", groupable: false, width: "13%" },
      {
        text: "Grace Days",
        value: "grace_days",
        groupable: false,
        width: "13%",
      },
      { text: "Default", value: "is_default", groupable: false },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: 100,
        groupable: false,
      },
    ],
    keepOpen: true,
    additionalSettings: false,
    form: null,
    snackbarMessage: null,
    formType: null,
    validForm: true,
  }),
  async mounted() {
    this.loadPlans();
    this.initForm();
  },
  computed: {
    currencies() {
      let currency_codes = _.set([
        "BDT",
        "EUR",
        "XOF",
        "BGN",
        "BAM",
        "BBD",
        "XPF",
        "EUR",
        "BMD",
        "BND",
        "BOB",
        "BHD",
        "BIF",
        "XOF",
        "BTN",
        "JMD",
        "NOK",
        "BWP",
        "WST",
        "USD",
        "BRL",
        "BSD",
        "GBP",
        "BYR",
        "BZD",
        "RUB",
        "RWF",
        "RSD",
        "USD",
        "EUR",
        "TMT",
        "TJS",
        "RON",
        "NZD",
        "XOF",
        "USD",
        "GTQ",
        "GBP",
        "EUR",
        "XAF",
        "EUR",
        "JPY",
        "GYD",
        "GBP",
        "EUR",
        "GEL",
        "XCD",
        "GBP",
        "XAF",
        "USD",
        "GNF",
        "GMD",
        "DKK",
        "GIP",
        "GHS",
        "OMR",
        "TND",
        "JOD",
        "HRK",
        "HTG",
        "HUF",
        "HKD",
        "HNL",
        "AUD",
        "VEF",
        "USD",
        "ILS",
        "USD",
        "EUR",
        "NOK",
        "PYG",
        "IQD",
        "PAB",
        "XPF",
        "PGK",
        "PEN",
        "PKR",
        "PHP",
        "NZD",
        "PLN",
        "EUR",
        "ZMK",
        "MAD",
        "EUR",
        "EGP",
        "ZAR",
        "USD",
        "EUR",
        "VND",
        "SBD",
        "ETB",
        "SOS",
        "ZWL",
        "SAR",
        "EUR",
        "ERN",
        "EUR",
        "MDL",
        "MGA",
        "EUR",
        "MAD",
        "EUR",
        "UZS",
        "MMK",
        "XOF",
        "MOP",
        "MNT",
        "USD",
        "MKD",
        "MUR",
        "EUR",
        "MWK",
        "MVR",
        "EUR",
        "USD",
        "XCD",
        "MRO",
        "GBP",
        "UGX",
        "TZS",
        "MYR",
        "MXN",
        "ILS",
        "EUR",
        "USD",
        "SHP",
        "EUR",
        "FJD",
        "FKP",
        "USD",
        "DKK",
        "NIO",
        "EUR",
        "NOK",
        "NAD",
        "VUV",
        "XPF",
        "XOF",
        "AUD",
        "NGN",
        "NZD",
        "NPR",
        "AUD",
        "NZD",
        "NZD",
        "EUR",
        "XOF",
        "CHF",
        "COP",
        "CNY",
        "XAF",
        "CLP",
        "AUD",
        "CAD",
        "XAF",
        "XAF",
        "CDF",
        "CZK",
        "EUR",
        "AUD",
        "CRC",
        "ANG",
        "CVE",
        "CUP",
        "SZL",
        "SYP",
        "ANG",
        "KGS",
        "KES",
        "SSP",
        "SRD",
        "AUD",
        "KHR",
        "XCD",
        "KMF",
        "STD",
        "EUR",
        "KRW",
        "EUR",
        "KPW",
        "KWD",
        "XOF",
        "EUR",
        "SLL",
        "SCR",
        "KZT",
        "KYD",
        "SGD",
        "SEK",
        "SDG",
        "DOP",
        "XCD",
        "DJF",
        "DKK",
        "USD",
        "EUR",
        "YER",
        "DZD",
        "USD",
        "UYU",
        "EUR",
        "USD",
        "LBP",
        "XCD",
        "LAK",
        "AUD",
        "TWD",
        "TTD",
        "TRY",
        "LKR",
        "CHF",
        "EUR",
        "TOP",
        "LTL",
        "EUR",
        "LRD",
        "LSL",
        "THB",
        "EUR",
        "XOF",
        "XAF",
        "USD",
        "LYD",
        "EUR",
        "XCD",
        "AED",
        "EUR",
        "XCD",
        "AFN",
        "XCD",
        "USD",
        "ISK",
        "IRR",
        "AMD",
        "ALL",
        "AOA",
        "USD",
        "ARS",
        "AUD",
        "EUR",
        "AWG",
        "INR",
        "EUR",
        "AZN",
        "EUR",
        "IDR",
        "UAH",
        "QAR",
        "MZN",
      ]);
      return currency_codes.sort();
    },
    ...mapGetters([
      "getRules",
      "getAuthUser",
      "getPlans",
      "getToggleSlider",
      "getNewPlan",
      "getPlanAddStatus",
      "getPlansLoadStatus",
      "getLoaderStatus",
      "getPlan",
      "getPlanUpdateStatus",
      "getPlanDeleteStatus",
      "getPlanErrors",
    ]),
  },
  methods: {
    async setAsDefault(plan = null) {
      this.form = new Form(plan);
      this.form.is_default = 1;
      let payload = Object.assign({}, this.form);
      this.setPlan(payload);
      await this.updatePlan();
      await this.loadPlans();
    },
    capitalizeTheFirstLetterOfEachWord(words = null) {
      let letter = "";
      if (words !== "" && words !== null && words !== undefined) {
        words = words.replace("_", " ");
        let separateWord = words.toLowerCase().split(" ");
        for (let i = 0; i < separateWord.length; i++) {
          separateWord[i] =
            separateWord[i].charAt(0).toUpperCase() +
            separateWord[i].substring(1);
        }
        letter = separateWord.join(" ") + " Plans";
      }
      return letter;
    },
    initForm() {
      let plan = this.getNewPlan;
      this.form = new Form(plan);
    },
    minimizeList(status) {
      this.mini = status;
    },
    async openPlanSlider(state, id = null) {
      this.formType = state;
      if (state === "add") {
        this.setToggleSlider(true);
      } else if (state === "edit") {
        this.setPlanById(id);
        let plan = await this.getPlan;
        this.form = new Form(plan);
        this.setToggleSlider(true);
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        if (this.formType === "add") {
          let payload = Object.assign({}, this.form);
          this.setNewPlan(payload);
          await this.addPlan();
          if (this.getPlanAddStatus === 2) {
            this.setToggleSlider(false);
            this.snackbarMessage = "Plan Added Successfully";
            this.$refs.snackbar.type = "success";
            this.$refs.snackbar.show = true;
            this.loadPlans();
            this.initForm();
          }
        } else if (this.formType === "edit") {
          let payload = Object.assign({}, this.form);
          this.setPlan(payload);
          await this.updatePlan();
          if (this.getPlanUpdateStatus === 2) {
            this.setToggleSlider(false);
            this.snackbarMessage = "Plan Updated Successfully";
            this.$refs.snackbar.type = "success";
            this.$refs.snackbar.show = true;
            this.loadPlans();
            this.initForm();
          }
        }
        this.close();
      }
    },
    close() {
      this.initForm();
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.setToggleSlider(false);
    },
    daysText(days) {
      if (days > 1) {
        return days + " Days";
      } else if (days === 1) {
        return days + " Day";
      } else {
        return "N/A";
      }
    },
    capitalize(words) {
      if (words !== "" && words !== null) {
        return words.toUpperCase();
      }
    },
    async deletePlanItem(item) {
      let planItem = Object.assign({}, item);
      await this.deletePlan(planItem);
      this.loadPlans();
      if (this.getPlanDeleteStatus === 3) {
        this.snackbarMessage = this.getPlanErrors.error;
        this.$refs.snackbar.type = "error";
        this.$refs.snackbar.show = true;
      }
    },
    ...mapActions([
      "logout",
      "loadPlans",
      "addPlan",
      "updatePlan",
      "deletePlan",
    ]),
    ...mapMutations([
      "setToggleSlider",
      "setNewPlan",
      "setPlan",
      "setPlanById",
    ]),
  },
  watch: {},
};
</script>

<style scoped>
.max-width {
  max-width: 70%;
  margin: 0 auto;
}
.custom-upload-file {
  box-shadow: none !important;
  border: dashed 1px #9e9e9e !important;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  background-color: white !important;
  text-align: left !important;
}
</style>
